function mobile_menu_func() {
    $('li.dropdown').on("click", function () {
        $(this).removeClass('open_dropdown_menu');
    });
    var mobile_menu = $('.header_top_inner_right').clone();
    $('.top_menu').prepend(mobile_menu);
    var mobile_call = $('.call').clone();
    $('.top_menu').append(mobile_call);
    var mobile_social = $('.social_content').clone();
    $('.top_menu').append(mobile_social);
}

function mobile_menu_func_reverse() {
    $('li.dropdown').off("click");
    $('.top_menu').find('.call').remove();
    $('.top_menu').find('.social_content').remove();
    $('.top_menu').find('.header_top_inner_right').remove();
}

var maskCheckbox = function () {
    $(this).toggleClass('checked');
}
var messengerOpen = function (e) {
    e.preventDefault();
    $('.ask-question-form-content').show();
    $('.success-message-place').hide().addClass('dn');
    $('body').addClass('messenger_opened');
    $('.messenger_inner').slideDown();


}
var messengerClose = function (e) {
    e.preventDefault();
    $('body').removeClass('messenger_opened');
    $('.messenger_inner').slideUp();
}
var changeCount = function (e) {
    e.preventDefault();
    var $val = $(this).parents('.count_block').find('input').val();
    var maxVal = $(this).parents('.count_block').find('input').data('maxcount');
    if ($(this).hasClass('decrease')) {
        if ($val > 1) {
            $val--;
        }
    } else {
        if ($val < maxVal) {
            $val++;
        }
    }
    $(this).parents('.count_block').find('input').val($val);
    $(this).parents('.count_block').find('input').trigger('change');
}

var countSync = function () {
    if ($(this).val() < 1) {
        $(this).val(1);
    }
    if ($(this).parents('.count_block').hasClass('count_from') && $(this).val() > $(this).parents('.form_field').find('.count_to input').val()) {
        $(this).parents('.form_field').find('.count_to input').val($(this).val());
    } else if ($(this).parents('.count_block').hasClass('count_to') && $(this).val() < $(this).parents('.form_field').find('.count_from input').val()) {
        $(this).parents('.form_field').find('.count_from input').val($(this).val());
    }
};




$(document).ready(function () {
    $('.messenger_open').click(messengerOpen);
    $('.messenger_close').click(messengerClose);
    $('.checkbox_button').click(maskCheckbox);
    $('.count_block a').click(changeCount);
    $('.count_block input').on('change', countSync);


    ;

    $('.currency_button').click(function (e) {
        e.preventDefault();
        if (window.innerWidth <= 1024) {
            $('.header_menu_inner').addClass('currency_opened');
        }
    });
    $('.currency_list li a').click(function (e) {
        if (!$(this).parents('li').hasClass('active')) {
            $('.currency_list li.active').removeClass('active');
            $(this).parents('li').addClass('active');
        }
    })
    $('.currency_close, .currency_list li.active a').click(function (e) {
        e.preventDefault();
        $('.header_menu_inner').removeClass('currency_opened');
        // $('.header_menu_inner').width(window.innerWidth*.8);
    });

    $('.currency_list').click(function (e) {
        if (!$(e.target).is('.list_container *')) {
            $('.header_menu_inner').removeClass('currency_opened');
        }
    });

    $('.currency_button').hover(
        function () {
            if (window.innerWidth > 1024) {
                $('.currency_block').addClass('hovered');
                setTimeout(function () {
                    if ($('.currency_block').hasClass('hovered')) {
                        $('.currency_list').stop(true, true).fadeIn(500);
                    }
                    ;
                }, 200)
            }

        }, function () {
            $('.currency_block').removeClass('hovered');
        });

    $('.currency_block').hover(function () {
    }, function () {
        if (window.innerWidth > 1024) {
            $('.currency_list').fadeOut(500);
        }
    });

    $('.submenu_button').hover(
        function () {
            if (window.innerWidth > 1024) {
                var $item = $(this).parents('.menu_item');
                $item.addClass('hovered');
                setTimeout(function () {
                    if ($item.hasClass('hovered')) {
                        $item.find('.submenu_list').stop(true, true).fadeIn(500);
                    }
                    ;
                }, 200)
            }

        }, function () {
            $(this).parents('.menu_item').removeClass('hovered');
        });

    $('.menu_item').hover(function () {
    }, function () {
        if (window.innerWidth > 1024) {
            $(this).find('.submenu_list').fadeOut(500);
        }
    });

    $('.submenu_button').click(function (e) {
        e.preventDefault();
    })

    $('.sublevel_button').hover(
        function () {
            if (window.innerWidth > 1024) {
                var $item = $(this).parents('.has_sublevel');
                $item.addClass('hovered');
                setTimeout(function () {
                    if ($item.hasClass('hovered')) {
                        $item.addClass('opened').find('.sublevel_menu').stop(true, true).fadeIn(500);
                    }
                    ;
                }, 200)
            }

        }, function () {
            $(this).parents('.has_sublevel').removeClass('hovered');
        });

    $('.has_sublevel').hover(function () {
    }, function () {
        if (window.innerWidth > 1024) {
            $(this).removeClass('opened').find('.sublevel_menu').fadeOut(500);
        }
    });


    $('.submenu_button').click(function (e) {
        e.preventDefault();

        if (window.innerWidth <= 1024) {
            if ($(this).parents('.menu_item').hasClass('opened')) {
                $(this).parents('.menu_item').removeClass('opened').find('.submenu_list').slideUp(500);
            } else {
                $('.menu_item').removeClass('opened');
                $('.submenu_list').slideUp(500);
                $(this).parents('.menu_item').find('.has_sublevel').removeClass('opened');
                $(this).parents('.menu_item').find('.sublevel_menu').hide();
                $(this).parents('.menu_item').addClass('opened').find('.submenu_list').stop(true, true).slideDown(500);
            }
        }
    });


    $('.sublevel_button').click(function (e) {
        e.preventDefault();
        if (window.innerWidth <= 1024) {
            if ($(this).parents('.has_sublevel').hasClass('opened')) {
                $(this).parents('.has_sublevel').removeClass('opened').find('.sublevel_menu').slideUp(500);
            } else {
                $('.has_sublevel').removeClass('opened');
                $('.sublevel_menu').slideUp(500);
                $(this).parents('.has_sublevel').addClass('opened').find('.sublevel_menu').stop(true, true).slideDown(500);
            }
        }
    });

    $('.menu_button').click(function (e) {
        e.preventDefault();
        $('.header_menu_inner').animate({scrollTop: 0}, 0);
        $('.menu_item,.has_sublevel').removeClass('opened');
        $('.submenu_list, .sublevel_menu').hide();
        $('body,html').addClass('menu_opened');
    });

    $('.menu_close').click(function (e) {
        e.preventDefault();
        $('body,html').removeClass('menu_opened');
    });

    $('body').click(function (e) {
        if ($('body').hasClass('menu_opened') && !$(e.target).is('.header_menu_block *') && !$(e.target).is('.menu_button')) {
            $('.menu_close').trigger('click');
        }
    })


    $(window).resize(function () {
        if (window.innerWidth > 1024) {
            $('body,html').removeClass('menu_opened');
            $('.currency_block').removeClass('opened');
            $('.header_menu_inner').css('min-width', 0).removeClass('currency_opened');
            $('.menu_item,.has_sublevel').removeClass('opened');
            $('.submenu_list, .sublevel_menu').hide();
        } else if (window.innerWidth < 768) {
            $('.header_menu_inner').css('min-width', window.innerWidth - 54);
        } else {
            $('.header_menu_inner').css('min-width', window.innerWidth - 120);

        }

    });


    $('.slider_button').hover(function () {
        $('.slider_button .fa-chevron-right').css('padding-left', '10px').delay(200);
    }, function () {
        $('.slider_button .fa-chevron-right').css('padding-left', '20px').delay(200);
    });


    /* need to review */

    /*
    var travelers_items_height = $('.usefull_information  .travelers_items').height();
    $('.usefull_information  .travelers_items').hover(function() {
      //  $(this).find('.bottom').addClass('news_hover');
          //  $(this).find('.bottom').css({ 'transition':'0.5s','transform':'translate(0, -30%)',  'background':'#fff', 'width':'100%'})

        $(this).css('height',travelers_items_height + 30)
    }, function() {
      //  $(this).find('.bottom').removeClass('news_hover');
        //$(this).find('.bottom').css({ 'transition':'0.5s','transform':'translate(0, 0)',  'background':'transparent', 'width':'100%'});
        //$(this).css('height', 'auto')
    });
    */
    /* need to review */


    if ($('.go_to_next').length > 0) {
        $('.go_to_next').click(function (e) {
            $('body,html').animate({scrollTop: $('#custom_carousel').height()}, 700);
            return false;
        });
    }

    if ($('.dayly_slider').length > 0) {
        $('.dayly_slider .slider_inner').each(function () {
            $(this).slick({
                centerPadding: 0,
                autoplay: false,
                autoplaySpeed: 6000,
                fade: true,
                verticalSwiping: false,
                rtl: false,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            verticalSwiping: false,
                            fade: false,
                            rtl: false,
                        }
                    }
                ]
            });
        });
    }

    if ($('.clients_slider').length > 0) {
        $('.clients_slider .slider_line').slick({
            centerPadding: 0,
            slidesToShow: 7,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 6
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4
                    }
                },
                {
                    breakpoint: 481,
                    settings: {
                        slidesToShow: 3
                    }
                }
            ]
        })
    }

    if ($('.hotel_images').length > 0) {
        $('.big_images').slick({
            centerPadding: 0,
            autoplay: true,
            asNavFor: '.small_images',
            autoplaySpeed: 6000,
            fade: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        fade: false
                    }
                }
            ]
        });
        $('.small_images').slick({
            centerPadding: 0,
            arrows: false,
            slidesToShow: 10,
            asNavFor: '.big_images',
            focusOnSelect: true
        })
    }
    ;

    if ($('.bike_images').length > 0) {
        $('.bike_images').slick({
            centerPadding: 0,
            autoplay: true,
            autoplaySpeed: 6000,
            fade: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        fade: false
                    }
                }
            ]
        });
    }

    if ($('.main_slider').length > 0) {
        $('.main_slider').slick({
            centerPadding: 0,
            autoplay: true,
            arrows: false,
            autoplaySpeed: 6000,
            fade: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        fade: false
                    }
                }
            ]
        });

        $('.main_slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var currentNum = nextSlide + 1;
            $('.carousel_navbar ul').attr('class', 'pos' + currentNum);
            $('.carousel_navbar ul li a.active').removeClass('active');
            $('.carousel_navbar li:nth-child(' + currentNum + ') a').addClass('active');
        });

        $('.carousel_navbar ul a').click(function (e) {
            e.preventDefault();
            $('.main_slider').slick('slickGoTo', $(this).parent().index());
        })
    }
    if ($('.adv_slider').length > 0) {

        $('.adv_slider').each(function () {
            $(this).slick({
                centerPadding: 0,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1
                        }
                    }
                ]
            })
        });
    }

    var sideslider = $('[data-toggle=collapse-side]');
    var sel = sideslider.attr('data-target');
    var sel2 = sideslider.attr('data-target-2');
    sideslider.click(function (event) {
        $(sel).toggleClass('in');
        $(sel2).toggleClass('out');
        $('html').toggleClass('disable_scroll');
    });
    var window_width = $(window).width();

    if ($(window_width) < 1025 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        mobile_menu_func();
    } else {
        mobile_menu_func_reverse();
    }


    jQuery(window).resize(function () {

        if ($(window_width) < 1025 || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            mobile_menu_func_reverse();
            mobile_menu_func();
        } else {
            mobile_menu_func_reverse();
        }
    });
    if ($('.client-logos').length > 0) {
        $('.client-logos').slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: false,
            dots: false,
            pauseOnHover: true,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5
                }
            }, {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 520,
                settings: {
                    slidesToShow: 2
                }
            }]
        });
    }


    $(document).on('click', '#new_navbar .dropdown-menu li a', function () {
        $(this).closest('ul').css('display', 'none');

    });
    $(document).on('click', '#navbar .dropdown.open .dropdown-menu li a', function () {
        $(this).closest('ul').css('display', 'block');

    });

    // accardion js
    $('.expand_all').on('click',function () {
        if ($("#itinerary .accardion_item").hasClass('active') && $(this).hasClass('expand')) {
            $(this).addClass('hideAll');
            $("#itinerary .accardion_item").addClass('active')
        } else if (!$("#itinerary .accardion_item").hasClass('active')) {
            $(this).addClass('hideAll');
            $("#itinerary .accardion_item").addClass('active');

        } else {

            $("#itinerary .accardion_item").removeClass('active');
            $(this).removeClass('hideAll');
        }

        return false;
    });
    $('.expand_all.hideAll.expand').on('click',function () {
        $(this).removeClass('hideAll');
        $("#itinerary .accardion_item").removeClass('active');
        return false;
    });
    $('.custom_accordion').off().on('click', function () {
        $(".expand_all").addClass('expand');
        var elem = $(this).closest('.accardion_item');
        if (elem.hasClass('active')) {
            elem.removeClass('active')
        } else {
            elem.addClass('active')
        }
        return false;
    });


    if (window.innerWidth > 1024) {
        $('.open-in-web').addClass('active');
    } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
        $('.open-in-tablet').addClass('active');
    } else {
        $('.open-in-mobile').addClass('active');
    }
    var wh = window.innerWidth;
    $(window).resize(function () {
        var nwh = window.innerWidth;
        var rw = nwh - wh;
        if (rw > 10 || rw < -10) {
            if (window.innerWidth > 1024) {
                $('.open-in-mobile, .open-in-tablet').removeClass('active');
                $('.open-in-web').addClass('active');
            } else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
                $('.open-in-mobile, .open-in-web').removeClass('active');
                $('.open-in-tablet').addClass('active');
            } else {
                $('.open-in-web, .open-in-tablet').removeClass('active');
                $('.open-in-mobile').addClass('active');
            }
            ;
            $('#custom_carousel .slider_img_').css('max-height', window.innerHeight);
        }
        ;
        wh = nwh;

    }).trigger('resize');

    if($('.tour_menu_inner_mobile').length > 0) {
        $(document).on('click', '.tour_menu_inner_mobile a', function () {

            if ($('.tour_menu').hasClass('clicked') || $(this).hasClass('active')) {
                return false
            } else {
                $('.tour_menu').addClass('clicked');
                $('.tour_menu_inner_mobile a').removeClass('active');
                $(this).addClass('active');
                var activeLeft = $('.tour_menu_inner_mobile a.active').offset().left;
                var activeRight = $('.tour_menu_inner_mobile a.active').offset().left + $('.tour_menu_inner_mobile a.active').width();
                var menuLeft = $('.tour_menu_inner_mobile').offset().left;
                var menuRight = $('.tour_menu_inner_mobile').offset().left + $('.tour_menu_inner_mobile').width();
                var scrollLeft = $('.tour_menu_inner_mobile').scrollLeft();
                var target = $(this.hash);
                //scrollHorizontal(this);
                if (target.length == 0) {
                    target = $('a[name="' + this.hash.substr(1) + '"]');
                }
                if (target.length == 0) target = $('html');
                $('html, body').animate({scrollTop: target.offset().top - 45}, 1000, function () {
                    $('.tour_menu').removeClass('clicked');
                    if (!$(target).hasClass('active')) {
                        $(target).addClass('active')
                    }
                });
                if (activeLeft < menuLeft) {
                    scrollLeft += activeLeft - menuLeft
                    $('.tour_menu_inner_mobile').animate({scrollLeft: scrollLeft}, 300);
                }
                ;
                if (activeRight > menuRight) {

                    scrollLeft += activeRight - menuRight;
                    $('.tour_menu_inner_mobile').animate({scrollLeft: scrollLeft}, 300);
                }
            }


        });
    }

});


$(window).on("load", function () {

    $(document).on("click",'.review_drop',function () {
        $(this).parents('.current_tour_reviews').toggleClass('opened');
        return false;
    });


    var $service = null;
    var serviceNum = 0;
    var rowCount = 0;
    var rowLast = 0;

    function checkRow() {
        if (window.innerWidth > 991) {
            rowCount = 3;
        } else if (window.innerWidth > 599 && window.innerWidth < 992) {
            rowCount = 2;
        } else {
            rowCount = 1;
        }
        ;
    }

    function setServicePos() {
        checkRow();

        rowLast = Math.ceil(serviceNum / rowCount) * rowCount;
        var arrowPos = 'pos' + serviceNum % 3;
        if (window.innerWidth > 599 && window.innerWidth < 992) {
            arrowPos = 'pos' + serviceNum % 2;
        }
        if (rowLast > $('.book_with_Arara .col-lg-4').length) {
            rowLast = $('.book_with_Arara .col-lg-4').length;
        }

        if ($('.service_append').length > 0) {
            $('.service_append').remove();
            $('.book_with_Arara .col-lg-4:nth-child(' + rowLast + ')').after('<div class="service_append"><div class="append_inner already_opened ' + arrowPos + '"><div class="about_book_text">' + $service + '</div></div></div>');
        } else {
            $('.book_with_Arara .col-lg-4:nth-child(' + rowLast + ')').after('<div class="service_append"><div class="append_inner ' + arrowPos + '"><div class="about_book_text">' + $service + '</div></div></div>');
            $('.service_append .append_inner').slideDown();
        }

    };

    if ($('.book_with_Arara').length > 0) {
        $('.book_with_Arara .col-lg-4 div').each(function () {
            if ($(this).find('.about_tour_book').length > 0) {
                $(this).addClass('clickable');
            }
        })
    }

    $('.book_with_Arara .col-lg-4 div').click(function () {
        var $button = $(this);
        if ($(this).find('.about_tour_book').length > 0) {
            $service = $(this).find('.about_tour_book').html();
            serviceNum = $(this).parents('.col-lg-4').index() + 1;

            if ($('.service_append').length > 0 && $('.service_append').index() < $(this).parents('.col-lg-4').index()) {
                serviceNum = $(this).parents('.col-lg-4').index();

            }
            ;

            if ($(this).hasClass('clicked')) {
                $('.service_append .append_inner').slideUp(300);
                $(this).removeClass('clicked');
                setTimeout(function () {
                    $('.service_append').remove();
                }, 350)
            } else {
                if ($('.service_append').length > 0) {
                    $('.service_append .append_inner').slideUp();
                    $('.book_with_Arara .col-lg-4 div').removeClass('clicked');
                    $button.addClass('clicked');
                    setTimeout(function () {
                        $('.service_append').remove();
                        setServicePos();
                    }, 350)
                } else {
                    $button.addClass('clicked');
                    setServicePos();
                }
            }
        }

    });

    var _rowCount = rowCount;
    $(window).resize(function () {
        setTimeout(function () {
            checkRow();
            var _newCount = rowCount;
            if (_newCount != _rowCount) {
                if ($('.service_append').length > 0 && $('.service_append').index() < $(this).parents('.col-lg-4').index()) {
                    serviceNum = $(this).parents('.col-lg-4').index();
                }
                setServicePos();

            }
            ;
            _rowCount = rowCount;
        }, 100);

        if ($('.tour_banner img').length > 0) {
            $('.tour_banner').css('min-height', 0);
        }
    });


    // tour menu position top
    $('.more_less').click(function (e) {
        e.preventDefault();
        if ($('.landing_prelude').hasClass('showed')) {
            $('.landing_prelude').removeClass('showed').animate({height: 120}, 500);
        } else {
            $('.landing_prelude').addClass('showed').animate({height: $('.prelude_inner').height()}, 500);
        }

    });
    $('.more_less2').click(function (e) {
        e.preventDefault();
        if ($('.epilogue_inner').hasClass('showed')) {
            $('.epilogue_inner').removeClass('showed').animate({height: 96}, 500);
        } else {
            $('.epilogue_inner').addClass('showed').animate({height: $('.epilogue_text').height()}, 500);
        }

    });
    $(window).resize(function () {
        if (window.innerWidth < 768 && $('.landing_prelude .prelude_inner').height() > 120) {
            $('.landing_prelude').css('max-height', 'none');
            $('.more_less').css('display', 'inline');
            if ($('.landing_prelude').hasClass('showed')) {
                $('.landing_prelude').height($('.prelude_inner').height())
            } else {
                $('.landing_prelude').height(120);
            }
        }

        if (window.innerWidth < 768 && $('.epilogue_text').height() > 96) {
            $('.epilogue_inner').css('max-height', 'none');
            $('.more_less2').css('display', 'inline');
            if ($('.epilogue_inner').hasClass('showed')) {
                $('.epilogue_inner').height($('.epilogue_text').height())
            } else {
                $('.epilogue_inner').height(96);
            }
        }

    }).resize();

    if ($('.tm_container').length > 0) {


        $(window).scroll(function () {
            //onScroll(event);
            var menuTop = $('.tour_menu').offset().top;

            var menuLeft = $('.tour_menu_inner_mobile').offset().left;
            var menuRight = $('.tour_menu_inner_mobile').offset().left + $('.tour_menu_inner_mobile').width();


            var lastBlock = $($('.tour_menu_inner_mobile a:last-child').attr('href'));
            var lastBottom = lastBlock.offset().top + lastBlock.height() - 45;
            var scrollBottom = 0;
            var scrollSize = $(document).scrollTop();
            if (menuTop <= scrollSize) {
                $(".tm_container").addClass('fixed_block');
            } else {
                $(".tm_container").removeClass('fixed_block');
            }
            if (scrollSize >= lastBottom) {
                $(".tm_container").addClass('inactive');
            } else {
                $(".tm_container").removeClass('inactive');
            }
            ;
            if (!$('.tour_menu').hasClass('clicked')) {

                $('.tour_menu_inner_mobile a').each(function () {
                    var $active = $(this);
                    var $block = $($(this).attr('href'));

                    if ($(this).next() > 0) {
                        scrollBottom = $block.next().offset().top - 46;
                    } else {
                        scrollBottom = lastBottom;
                    }
                    if (scrollSize >= $block.offset().top - 45 && scrollSize < scrollBottom) {

                        $('.tour_menu_inner_mobile a').removeClass('active');
                        $active.addClass('active');

                    }
                });
            }

            var scrollLeft = $('.tour_menu_inner_mobile').scrollLeft();
            var activeLeft = $('.tour_menu_inner_mobile a.active').offset().left;
            var activeRight = $('.tour_menu_inner_mobile a.active').offset().left + $('.tour_menu_inner_mobile a.active').width();

            if (activeLeft < menuLeft) {
                scrollLeft += activeLeft - menuLeft
                $('.tour_menu_inner_mobile').animate({scrollLeft: scrollLeft}, 0);
            }

            if (activeRight > menuRight) {
                scrollLeft += (activeRight - menuRight);
                $('.tour_menu_inner_mobile').animate({scrollLeft: scrollLeft}, 0);
            }
        }).scroll();


        if (window.location.hash.length > 0) {
            $('a[href="' + window.location.hash + '"]').trigger('click');
        }

        $('.tm_container').addClass('showed');
    }


})

/*$(document).on('touchstart', '.tour_menu_inner_mobile_inner', function(e) {
    $(this).css('left', '0');
});*/

