if ('serviceWorker' in navigator) {
    // при регистрации указываем на js-файл с кодом serviceWorker’а
// получаем Promise объект
    navigator.serviceWorker.register(
        '/sw-arara.js'
    ).then(function(registration) {
        // при удачной регистрации имеем объект типа ServiceWorkerRegistration
        console.log('ServiceWorker registration', registration);
        // строкой ниже можно прекратить работу serviceWorker’а
        //registration.unregister();
    }).catch(function(err) {
        throw new Error('ServiceWorker error: ' + err);
    });
}
String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

getDayInformation = function(item,id, token, contentPlace){
    $.ajax({
        url: $(item).data('url'),
        type: 'post',
        cache:true,
        data: {
            _token: token,
            id: id
        },
        dataType: 'json',
        beforeSend: function () {
            $('#day-accordion-content-'+id).html('');
        },
        success: function (response) {

            contentPlace.html(response.html);
            contentPlace.find('.slider_inner').slick({
                centerPadding: 0,
                autoplay: true,
                autoplaySpeed: 6000,
                fade: true,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            fade: false
                        }
                    }
                ]
            });
            $('.custom_accordion_content').collapse('show');
            $(item).off('click').on('click', function () {
                $(".expand_all").addClass('expand');
                var elem = $(this).closest('.accardion_item');
                if (elem.hasClass('active')) {
                    elem.removeClass('active')
                } else {
                    elem.addClass('active')
                }
                return false;
            });
        }
    });
}
$(document).ready(function () {

    /*if(typeof LazyLoad != "undefined") {
        var myLazyLoad = new LazyLoad({
            elements_selector: ".lazy"
        });
    }*/

    //$('ul.arrow_type_list, ul.check_type_list').addClass('two_cols_list');
    if($('.days-accordion-title').length > 0){
        $('.open-in-web .days-accordion-title, .open-in-tablet .days-accordion-title,.open-in-mobile .days-accordion-title').each(function (index, item) {
            var id = $(item).data('id');
            var token = $(item).data('token');
            var contentPlace = $( '#day-accordion-content-'+id);

        });
        $('.days-accordion-title').each( function(index, item){
            var id = $(item).data('id');
            var token = $(item).data('token');
            var contentPlace = $( '#day-accordion-content-'+id);
            var parent = $(item).parent('.accardion_item');
            $(item).on('click', function () {
                getDayInformation(item, id, token, contentPlace);
            });
        });
    }
    if($('.feedback-voting').length > 0){
        $('.feedback-voting').each(function () {
            $(this).barrating({
                theme: 'fontawesome-stars'
            });
        });
    }
    if($('.payment-amount').length > 0){
        $('.payment-amount').on("keyup change",function () {
            var amount = $(this).val() * currentRate;
            amount = addCommas(amount.toFixed(2));
            $('#amd-place').html(amount + ' AMD');
            $('#amd-place').append('<input type="hidden" name="original_amount" value="'+$(this).val() * currentRate+'">');
        })
    }
    ajaxFormSubmit('#reused_form','contactsSubmit');

    ajaxFormSubmit('#feedback_form','feedbackSubmit');

    ajaxFormSubmit('#news_comment','newsCommentSubmit');

    ajaxFormSubmit('#events_form','eventsSubmit');

    ajaxFormSubmit('#motorbike-rental','motorbikeSubmit');

    ajaxFormSubmit('#footer-messenger-form','footerMessengerSubmit');

    //ajaxFormSubmit('#make-payment','makePayment');

    ajaxFormSubmit('#booking_form','bookNow');

    ajaxFormSubmit('#design-your-tour','designYourTour');

    initShowMore();

    makePaymentInit('#make-payment','makePayment');


    $('#myModal').on('shown.bs.modal', function (event) {
        $('.modal').not($(this)).each(function () {
            $(this).modal('hide');
        });
        document.getElementById("booking_form").reset();
        $('#book-start-date').remove();
        $('#book-end-date').remove();
        $('#book-date-price').remove();
        $('#form-tour-id').remove();
        $('#booking_form,.hide-submitted').show();
        $('#booking-success').addClass('hidden');
        if(typeof  $(event.relatedTarget).data('title') != "undefined" && typeof $(event.relatedTarget).data('id') != "undefined"){
            var title = $(event.relatedTarget).data('title');
            var id = $('<input />').attr({
                type: "hidden",
                name: "tour_id",
                id: "form-tour-id",
                value: $(event.relatedTarget).data('id'),
            });
            var start_date = $(event.relatedTarget).data('start-date');
            var end_date = $(event.relatedTarget).data('end-date');
            var date_price = $(event.relatedTarget).data('date-price');
            if(typeof start_date != "undefined" || typeof end_date != "undefined" ){
                $(event.currentTarget).find('form').append($('<input />').attr({
                    type: "hidden",
                    name: "start_date",
                    value: start_date,
                    id: 'book-start-date',
                }));
                $(event.currentTarget).find('form').append($('<input />').attr({
                    type: "hidden",
                    name: "end_date",
                    value: end_date,
                    id: 'book-end-date'
                }));
                $(event.currentTarget).find('form').append($('<input />').attr({
                    type: "hidden",
                    name: "date_price",
                    value: date_price,
                    id: 'book-date-price'
                }));
            }
            $(event.currentTarget).find('.modal-title').html(title);
            $(event.currentTarget).find('form').append(id);
        }
    });

    $('#load-more-reviews').on("click", function () { var self = $(this);
        $.ajax({
            url: self.attr('href'),
            type: "post",
            dataType: "json",
            data: {
                current_count: $('.current_tour_reviews').length,
                _token: self.data('token')
            },
            success: function (data) {
                var html = '';
                var html = data['html'];
                $('.reviews-container').append(html);
                if(data['allCount'] == $('.current_tour_reviews').length){
                    self.remove();
                }
            }
        });
        return false;
    });

    $('#guide-inner-feedback-more').on("click", function () {
        var self = $(this);
        $.ajax({
            url: self.attr('href'),
            type: "post",
            dataType: "json",
            data: {
                current_count: $('.current_tour_reviews').length,
                _token: self.data('token')
            },
            success: function (data) {
                var html = '';
                var html = data['html'];
                $('.reviews-container').append(html);
                if(data['allCount'] == $('.current_tour_reviews').length){
                    self.remove();
                }
            }
        });
        return false;
    });

    $('#tour-inner-guaranteed-more').on("click", function () {
        var self = $(this);
        $.ajax({
            url: self.attr('href'),
            type: "post",
            dataType: "json",
            data: {
                current_count: $('#guaranteed_tour_dates tbody tr').length,
                _token: self.data('token')
            },
            success: function (data) {
                var html = data['html'];
                var mobile_html = data['mobile_html'];
                $('#guaranteed_tour_dates tbody').append(html);
                $('#guaranteed_tour_dates_mobile').append(mobile_html);
                if(data['allCount'] == $('#guaranteed_tour_dates tbody tr').length){
                    self.remove();
                }
            }
        });
        return false;
    });
});
function addCommas(nStr)
{
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}
function ajaxFormSubmit (form, successCallback){
    form = $(form);
    form.submit(function(){
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: form.serialize(),
            beforeSend: function(){
                form.find('input').attr({disabled:"disabled"});
                form.find('button[type=submit]').attr({disabled:"disabled"}).addClass('loading--state');
                form.find('.form-error').removeClass('form-error');
                form.find('.error-message').html('');
            },
            success: function(data){
                form.find('button[type=submit]').removeAttr("disabled").removeClass('loading--state');
                form.find('input').removeAttr("disabled");
                if (typeof data.errors != "undefined" && data.errors != null) {
                    validateForm(form, data.errors);
                }
                else {
                    var fn = window[successCallback];
                    if (typeof fn === "function") {
                        fn(data);
                    }
                }
            }
        });
        return false;
    });

}

function makePaymentInit (form, successCallback){
    form = $(form);
    form.submit(function(){
        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            data: form.serialize(),
            beforeSend: function(){
                form.find('input').attr({disabled:"disabled"});
                form.find('button[type=submit]').attr({disabled:"disabled"}).addClass('loading--state');
                form.find('.form-error').removeClass('form-error');
                form.find('.error-message').html('');
                form.find('.field_block').addClass('disabled');
            },
            success: function(data){
                form.find('button[type=submit]').removeAttr("disabled").removeClass('loading--state');
                form.find('.field_block').removeClass('disabled');
                form.find('input').removeAttr("disabled");
                if (typeof data.errors != "undefined" && data.errors != null) {
                    validateForm(form, data.errors);
                }
                else {
                    var fn = window[successCallback];
                    if (typeof fn === "function") {
                        fn(data);
                    }
                }
            }
        });
        return false;
    });

}

contactSubmit = function (data) {
   document.getElementById('reused_form').reset();
    if (typeof grecaptcha != "undefined"){
        grecaptcha.execute(reCAPTCHA_site_key).then(function(token) {
            $('#g-recaptcha-field').val(token);
        });
    }
    $('#reused_form').slideUp(500);
    $('#contacts_success').slideDown(500);

}

feedbackSubmit = function (data) {
   document.getElementById('feedback_form').reset();
    if (typeof grecaptcha != "undefined"){
        grecaptcha.reset();
    }
    $('#feedback_form').slideUp(500);
    $('#feedback_success').slideDown(500);

}
contactsSubmit = function (data) {
   document.getElementById('reused_form').reset();
    if (typeof grecaptcha != "undefined"){
        grecaptcha.execute(reCAPTCHA_site_key).then(function(token) {
            $('#g-recaptcha-field').val(token);
        });
    }
    $('#reused_form').slideUp(500);
    $('#contact_success').slideDown(500);

}

newsCommentSubmit = function (data) {
   document.getElementById('news_comment').reset();
   $('#news_comment_success').slideDown();
   $('#news_comment').slideUp();
    if (typeof grecaptcha != "undefined"){
        grecaptcha.execute(reCAPTCHA_site_key).then(function(token) {
            $('#g-recaptcha-field').val(token);
        });
    }
    alert('ok');
}
eventsSubmit = function (data) {
   document.getElementById('events_form').reset();
    if (typeof grecaptcha != "undefined"){
        grecaptcha.execute(reCAPTCHA_site_key).then(function(token) {
            $('#g-recaptcha-field').val(token);
        });
    }
    alert('ok');
}
motorbikeSubmit = function (data) {
   document.getElementById('events_form').reset();
    alert('ok');
}
footerMessengerSubmit = function (data) {
   document.getElementById('footer-messenger-form').reset();
   $('.ask-question-form-content').slideUp();
   $('.success-message-place').slideDown().removeClass('dn');
}
designYourTour = function (data) {
   document.getElementById('design-your-tour').reset();
   $('#design-your-tour').slideUp();
   $('#design-your-tour-success').slideDown();

}

makePayment = function (data) {
   //document.getElementById('make-payment').reset();
   if(typeof data.formUrl != "undefined") {
       window.location.replace(data.formUrl);
   }
   else{
       console.log(data);
   }
}
bookNow = function (data) {
   document.getElementById('booking_form').reset();

   $('#booking_form, .hide-submitted').slideUp('slow');
   $('#booking-success').removeClass('hidden').slideDown('slow');
}


var validateForm = function(form, errors){
    form = $(form);

    $.each(errors,function(name, error){
        name = name.replaceAll('.','_');
        $('#error-' + name, form).html(error);
        $('#error-' + name, form).parent('.form-group').addClass('form-error');
        $('#error-' + name, form).parent('.select-box').addClass('form-error');
    });

    var elem = form.find('.form__error:first');
    if(elem.length != 0) {
        $('html, body').animate({
            scrollTop: elem.offset().top - 100
        }, 1000);
    }
    if (typeof grecaptcha != "undefined"){
        if(typeof recaptchaType != "undefined" && recaptchaType == 'v2'){

        }
        else {
            grecaptcha.execute(reCAPTCHA_site_key).then(function (token) {
                $('#g-recaptcha-field').val(token);
            });
        }
    }

}


function initShowMore() {
    $('.current_tour_reviews').each(function(){
        if ($(this).find('.review_inner').height() > 88 && window.innerWidth > 480 || $(this).find('.review_inner').height() > 108 && window.innerWidth <= 480) {

            $(this).find('.review_drop').css('display','block');
        } else {
            $(this).find('.review_drop').css('display','none');
        }
    })
};

$(window).resize(function(){
    initShowMore();
});